/** @typedef {typeof state} ModuleState */
/** @typedef {import('@/features/integrations/types/integrations.types').Integration} Integration */

import {
  INTEGRATIONS_DELETE_INTEGRATION,
  INTEGRATIONS_FETCH_ALL,
  INTEGRATIONS_FETCH_INTEGRATION_TYPES,
} from '@/features/integrations/stores/action.types.js'
import {
  INTEGRATIONS_SET_INTEGRATIONS,
  INTEGRATIONS_SET_SYSTEMS,
  INTEGRATIONS_SET_INTEGRATION_FILTERS,
} from '@/features/integrations/stores/mutation.types.js'

import IntegrationsApi from '@/features/integrations/api/integrations.api'

import { getVariable, removeVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'

const state = {
  /** @type {Array<Integration>} */ integrations: [],
  integrationsWarning: false,
  integrationFilters: [],
  systems: [],
}

const getters = {
  integrations: (/** @type {ModuleState} */ state) => state.integrations,

  integrationsWarning: (/** @type {ModuleState} */ state) => state.integrationsWarning,

  integrationById: (/** @type {ModuleState} */ state) => id =>
    state.integrations.find(integration => integration.id === id),

  integrationFilters: (/** @type {ModuleState} */ state) => state.integrationFilters,

  systemByCode: (/** @type {ModuleState} */ state) => (code) => {
    return state.systems.find(integration => integration.code === code)
  },

  systems: (/** @type {ModuleState} */ state) => state.systems,

  hasActiveIntegrations: (/** @type {ModuleState} */ state) => state.integrations.length > 0,
}

const actions = {
  /**
   * @param {object} context
   */
  async [INTEGRATIONS_FETCH_ALL]({ commit }) {
    const integrations = await IntegrationsApi.findAll()
    commit(INTEGRATIONS_SET_INTEGRATIONS, integrations)
  },

  /**
   * @param {object} context
   */
  async [INTEGRATIONS_FETCH_INTEGRATION_TYPES]({ commit }) {
    const integrationTypes = await IntegrationsApi.getFullIntegrationsList()
    commit(INTEGRATIONS_SET_SYSTEMS, integrationTypes.results)
    commit(INTEGRATIONS_SET_INTEGRATION_FILTERS, integrationTypes.filters)
  },

  /**
   * @param {object} context
   * @param {number} integrationId
   */
  async [INTEGRATIONS_DELETE_INTEGRATION]({ dispatch, getters }, integrationId) {
    await IntegrationsApi.delete(integrationId)
    await dispatch(INTEGRATIONS_FETCH_ALL)

    const userId = getters.user.id
    const incomingOrdersStoredFilters = getVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_FILTERS)

    if (incomingOrdersStoredFilters) {
      delete incomingOrdersStoredFilters[integrationId]
      delete incomingOrdersStoredFilters.all
      setVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_FILTERS, incomingOrdersStoredFilters)
    }

    const savedIntegrationId = getVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_LAST_INTEGRATION_ID)

    if (integrationId === savedIntegrationId) {
      removeVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_LAST_INTEGRATION_ID)
    }
  },
}

const mutations = {
  /**
   * @param {ModuleState} state
   * @param {Array<Integration>} integrations
   */
  [INTEGRATIONS_SET_INTEGRATIONS](state, integrations) {
    state.integrations = integrations
    if (integrations.length) {
      state.integrationsWarning = integrations.some(integration => integration.failing_since)
    }
  },

  [INTEGRATIONS_SET_INTEGRATION_FILTERS](state, integrationFilters) {
    state.integrationFilters = integrationFilters
  },

  [INTEGRATIONS_SET_SYSTEMS](state, systems) {
    state.systems = systems
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
