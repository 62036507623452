import { computed, ref, watch } from 'vue'
import { fetchChats, fetchMessages } from '@/features/whatsapp-conversations/api/whatsapp-conversations.api'
import { defineStore } from 'pinia'
import { useStore } from 'vuex'
import { CONVERSATIONS_BETA_FEATURE } from '@/features/whatsapp-conversations/beta-features'
import { useUnreadCounterPolling } from '@/features/whatsapp-conversations/services/composables/use-unread-counter-polling'
import { createAbortController } from '@/common/utils/create-abort-controller'
import {
  type WhatsappMessage,
  type WhatsappChat,
} from '@/features/whatsapp-conversations/types/conversations-data-parsers'
import { logError } from '@/common/utils/errors/error-handlers'
import { isCancel } from 'axios'
import ToastService from '@/common/services/toast.service'
import i18n from '@/application/i18n/i18n'
import type { RequestStatus } from '@/types/api'

type MessageRequestType = 'initialMessages' | 'nextMessages'
type ChatRequestType = 'initialChats' | 'nextChats'

type RequestType = MessageRequestType | ChatRequestType

type Statuses = Partial<Record<RequestType, RequestStatus['status']>>
type AbortControllers = Partial<Record<MessageRequestType, AbortController>>

type FetchMessagesParams = {
  chatId: string
  requestType: MessageRequestType
}
type AdditionalFetchMessagesParams = { onBeforeStateUpdate?: () => void }

export const useConversationsStore = defineStore('whatsappConversations', () => {
  const statuses = ref<Statuses>({})
  const abortControllers = ref<AbortControllers>({})
  const whatsappChats = ref<WhatsappChat[]>([])
  const selectedChatId = ref<string>()
  const nextPageChatsCursor = ref<string | null>(null)
  const nextPageMessagesCursor = ref<string | null>(null)
  const whatsAppMessages = ref<WhatsappMessage[]>([])

  const vuexStore = useStore()
  const hasConversationsBetaFeature = computed<boolean>(() =>
    vuexStore.getters.hasBetaFeature(CONVERSATIONS_BETA_FEATURE),
  )
  const { startPolling, stopPolling, unreadMessagesCount, unreadMessagesCounterText } = useUnreadCounterPolling()

  watch(
    hasConversationsBetaFeature,
    (newValue) => {
      newValue ? startPolling() : stopPolling()
    },
    { immediate: true },
  )

  const handleError = (error: unknown, toastMessage: string = i18n.t('Something went wrong')) => {
    if (isCancel(error)) {
      return
    }
    ToastService.error(toastMessage)
    logError(error)
  }

  const fetchWhatsappChats = async (requestType: ChatRequestType = 'initialChats') => {
    statuses.value[requestType] = 'pending'
    try {
      const { data: chats, next } = await fetchChats({
        cursor: requestType === 'nextChats' ? nextPageChatsCursor.value : null,
      })

      if (requestType === 'initialChats') {
        whatsappChats.value = chats
      } else {
        whatsappChats.value.push(...chats)
      }
      nextPageChatsCursor.value = next
      statuses.value[requestType] = 'ok'
    } catch (error) {
      // TODO: ADD REQUEST SPECIFIC TEXTS AND TRANSLATIONS:
      handleError(error, i18n.t('Something went wrong'))
      statuses.value[requestType] = 'error'
    }
  }

  const fetchWhatsappMessages = async (
    { chatId, requestType }: FetchMessagesParams,
    { onBeforeStateUpdate }: AdditionalFetchMessagesParams = {},
  ) => {
    statuses.value[requestType] = 'pending'
    try {
      if (requestType === 'initialMessages') {
        clearMessages()
      }
      // we need to do this to avoid race condition when switching chats
      // (could accidentally load messages from previous chat, instead of current)
      abortControllers.value[requestType]?.abort()
      abortControllers.value[requestType] = createAbortController()

      const axiosConfig = { signal: abortControllers.value[requestType]?.signal }

      const { messages, next } = await fetchMessages(
        {
          chatId,
          cursor: requestType === 'nextMessages' ? nextPageChatsCursor.value : null,
        },
        axiosConfig,
      )

      onBeforeStateUpdate?.()

      whatsAppMessages.value.push(...messages)
      nextPageMessagesCursor.value = next

      statuses.value[requestType] = 'ok'
    } catch (error) {
      // TODO: ADD REQUEST SPECIFIC TEXTS AND TRANSLATIONS:
      handleError(error, i18n.t('Something went wrong'))
      statuses.value[requestType] = 'error'
    }
  }

  const clearSelectedChat = () => {
    selectedChatId.value = undefined
    clearMessages()
  }

  const clearMessages = () => {
    whatsAppMessages.value = []
  }

  const clearWhatsappConversationStore = () => {
    clearSelectedChat()
    whatsappChats.value = []
  }

  const currentWhatsappChat = computed(() =>
    whatsappChats.value.find(whatsappChat => whatsappChat.id === selectedChatId.value),
  )

  watch(currentWhatsappChat, (currentChat) => {
    if (currentChat) {
      // BE auto-marks all new messages as read as soon as we open active chat
      currentChat.unread_messages_count = 0
    }
  })

  return {
    statuses,
    abortControllers,
    whatsappChats,
    whatsAppMessages,
    unreadMessagesCount,
    unreadMessagesCounterText,
    selectedChatId,
    nextPageChatsCursor,
    nextPageMessagesCursor,
    currentWhatsappChat,
    fetchWhatsappChats,
    fetchWhatsappMessages,
    clearMessages,
    clearSelectedChat,
    clearWhatsappConversationStore,
  }
})
