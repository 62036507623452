<template>
  <div
    v-click-outside="clickOutsideHandler"
    class="onboarding-wizard-profile__wrapper"
    :class="{ 'onboarding-wizard-profile__wrapper--open': dropdownOpen }"
  >
    <component
      :is="isDesktopView ? 'button' : 'div'"
      :type="isDesktopView ? 'button' : null"
      :class="{ 'menu-item__link': isDesktopView }"
      class="onboarding-wizard-profile"
      data-test="onboarding-wizard-profile"
      @click="isDesktopView ? toggleDropdown() : null"
    >
      <div class="onboarding-wizard-profile__inner">
        <Fa :icon="faUser" />
        <OWProgressBar size="small" />
      </div>
      <Fa
        v-if="isDesktopView"
        :icon="faChevronDown"
        class="onboarding-wizard-profile__icon ui-icon--small"
      />
    </component>
    <transition name="sc-fade">
      <OWDropdown
        v-if="isDesktopView"
        v-show="dropdownOpen"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { directive as vClickOutside } from 'click-outside-vue3'
import OWProgressBar from '@/features/onboarding-wizard/components/OWProgressBar.vue'
import OWDropdown from '@/features/onboarding-wizard/components/OWDropdown.vue'
import Fa from 'vue-fa'
import { faUser, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

// Props
defineProps<{
  isDesktopView: boolean
}>()

const dropdownOpen = ref(false)

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const clickOutsideHandler = (event: Event) => {
  const target = event.target as HTMLElement | null
  const dropdown = document.querySelector('.onboarding-wizard-profile__wrapper')

  if (dropdown && target instanceof Node && !dropdown.contains(target)) {
    dropdownOpen.value = false
  }
}

// Add and remove the click outside event listener
onMounted(() => {
  document.addEventListener('click', clickOutsideHandler)
})

onUnmounted(() => {
  document.removeEventListener('click', clickOutsideHandler)
})

</script>

<style lang="scss" scoped>
$animation-speed: 0.25s;

.sc-fade-enter-active,
.sc-fade-leave-active {
  position: absolute;

  --fade-transition-time: 0.25s;
  --fade-transition-timing: ease-out;
}

.onboarding-wizard-profile__wrapper {
  position: relative;
  height: 100%;

  @include breakpoint-max-width(large) {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: $sc-spacing-12;
  }
}

.onboarding-wizard-dropdown {
  position: absolute;
  top: calc(100% + $sc-spacing-4);
  right: 0;
  width: 280px;
  background-color: $sc-white;
  padding: $sc-spacing-20;
  border-radius: $sc-border-radius;
  box-shadow: 0 4px 10px rgb(17 40 87 / 7%);
}

.onboarding-wizard-profile:focus {
  box-shadow: none;
}

.onboarding-wizard-profile__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-right: $sc-spacing-8;

  @include breakpoint-min-width(large) {
    width: 43px;
    height: 43px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $sc-light-theme-2;
    border-radius: 50px;
  }
}

.onboarding-wizard-profile__inner .vue-fa {
  position: relative;
  width: 45%;
  height: 45%;
}

.onboarding-wizard-profile__inner .onboarding-wizard-progress-bar {
  position: absolute;
  width: 100%;
  top: calc(100% - $sc-spacing-8);
}

.onboarding-wizard-profile__wrapper .onboarding-wizard-profile__icon {
  transition: transform $animation-speed;
}

.onboarding-wizard-profile__wrapper--open .onboarding-wizard-profile__icon {
  transform: rotate(-180deg);
}

.onboarding-wizard-dropdown__button {
  display: block;
  text-align: center;
  margin-top: $sc-spacing-20;
}
</style>
