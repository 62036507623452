<template>
  <component
    :is="expandable ? 'UiExpandablePanel' : 'div'"
    :expanded="expandable ? panelExpanded : null"
    @toggle="expandable ? toggleExpandablePanel() : null"
  >
    <template #title>
      <div class="onboarding-wizard-sidebar__section--title">
        <h3>{{ t('Account Setup') }}</h3>
        <span>{{ displayedStepNumber }} / {{ onboardingWizardStepsTotal }}</span>
      </div>
    </template>
    <template v-if="!expandable">
      <div class="onboarding-wizard-sidebar__section--title">
        <h3>{{ t('Account Setup') }}</h3>
        <span>{{ displayedStepNumber }} / {{ onboardingWizardStepsTotal }}</span>
      </div>
    </template>
    <nav
      :aria-label="t('Account Setup')"
      class="onboarding-wizard-sidebar__nav"
    >
      <ul class="onboarding-wizard-sidebar__ul">
        <li>
          <span class="onboarding-wizard-sidebar__item completed">
            <span class="onboarding-wizard-sidebar__text">
              <Fa
                :icon="faRocket"
                class="ui-icon--small"
              />
              {{ t('Create account') }}
            </span>
            <span class="onboarding-wizard-sidebar__icon">
              <Fa
                :icon="faCircleCheck"
                class="ui-icon--medium"
              />
            </span>
          </span>
        </li>
        <li
          v-for="step in getPrimaryStepsOnboardingWizard"
          :id="step.id"
          :key="step.id"
        >
          <span
            :data-test="`onboarding-wizard-sidebar-list-item-${step.id}`"
            class="onboarding-wizard-sidebar__item"
            :class="[
              {
                completed: step.is_complete,
                disabled: isStepDisabled(step),
                active: isStepActive(step.id as StepKey),
              },
            ]"
          >
            <span class="onboarding-wizard-sidebar__text">
              <Fa
                :icon="steps[step.id as StepKey].icon"
                class="ui-icon--small"
              />
              {{ steps[step.id as StepKey].title }}
            </span>
            <transition name="sc-fade">
              <span
                v-if="step.is_complete"
                class="onboarding-wizard-sidebar__icon"
              >
                <Fa
                  :icon="faCircleCheck"
                  class="ui-icon--medium"
                />
              </span>
            </transition>
          </span>
          <UiTooltip
            v-if="step.is_complete"
            :for="step.id"
            :text="t('onboarding.tip_about_editing')"
          />
        </li>
      </ul>
    </nav>
  </component>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Fa from 'vue-fa'
import {
  faRocket,
  faCircleCheck,
  faUser,
  faShop,
  faStar,
  faAddressCard,
  faEuroSign,
  faTruck,
} from '@fortawesome/pro-solid-svg-icons'
import { UiTooltip } from '@sendcloud/ui-library'
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import type { Step } from '@/features/onboarding-wizard/types/onboarding-wizard.types'

type StepKey =
  | 'your-details'
  | 'integrations'
  | 'your-address'
  | 'payment-method'
  | 'carriers'
  | 'your-brand'

defineProps<{
  expandable?: boolean
}>()

const route = useRoute()

const { t } = useI18n()

const panelExpanded = ref(false)

const store = useStore()

const getPrimaryStepsOnboardingWizard = computed<Step[]>(() =>
  store.getters.getPrimaryStepsOnboardingWizard,
)

const onboardingWizardStepsTotal = computed<number>(() =>
  store.getters.onboardingWizardStepsTotal,
)

const onboardingWizardStepsCompleted = computed<number>(() =>
  store.getters.onboardingWizardStepsCompleted,
)

const displayedStepNumber = computed<number>(() => {
  if (!route.path.includes('/onboarding/')) {
    return onboardingWizardStepsCompleted.value
  }

  const currentStepIndex = getPrimaryStepsOnboardingWizard.value.findIndex(
    ({ id }) => id === currentStepId.value,
  )

  return currentStepIndex + 2
})

const currentStepId = computed<string>(() => {
  const path = route.path.split('/onboarding/')
  return path[1] || ''
})

const steps = computed<Record<StepKey, { title: string, icon: IconDefinition }>>(() => ({
  'your-details': {
    title: t('Your details'),
    icon: faUser,
  },
  'integrations': {
    title: t('Connect your store'),
    icon: faShop,
  },
  'your-address': {
    title: t('Add your address'),
    icon: faAddressCard,
  },
  'payment-method': {
    title: t('onboarding.payment.title'),
    icon: faEuroSign,
  },
  'carriers': {
    title: t('onboarding_wizard.carriers.page_title'),
    icon: faTruck,
  },
  'your-brand': {
    title: t('Your brand'),
    icon: faStar,
  },
}))

const toggleExpandablePanel = () => {
  panelExpanded.value = !panelExpanded.value
}

const isStepActive = (stepId: StepKey) => {
  return currentStepId.value.includes(stepId)
}

const isStepDisabled = (step: Step) => {
  return !isStepActive(step.id as StepKey) && !step.is_complete
}
</script>

<style lang="scss" scoped>
.onboarding-wizard-sidebar__section--title {
  padding: $sc-spacing-8 $sc-spacing-8 $sc-spacing-8 $sc-spacing-12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h3 {
    padding: 0;
    margin: 0;
    font-size: 1.125rem;
  }

  span {
    font-size: 1.125rem;
  }
}

.onboarding-wizard-sidebar__nav {
  padding: 0;
  margin: 0;
}

.onboarding-wizard-sidebar__ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.onboarding-wizard-sidebar__text {
  display: flex;
  align-items: center;
  transition: text-decoration 200ms;
  color: $sc-dark-blue;

  svg {
    margin-right: $sc-spacing-12;
  }
}

.onboarding-wizard-sidebar__item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: $sc-spacing-12 $sc-spacing-8 $sc-spacing-12 $sc-spacing-12;
  border-radius: $sc-border-radius;
  border: none;
  text-decoration: none;
  background-color: transparent;

  &.active {
    font-weight: 600;
    background-color: $sc-informative-10;
  }

  &:hover {
    background: color.mix($sc-informative, #fff, 5%);
  }

  &.completed,
  &.disabled {
    .onboarding-wizard-sidebar__text {
      color: $sc-dark-blue-75;
    }
  }

  &.disabled {
    .onboarding-wizard-sidebar__text {
      opacity: 0.75;
    }
  }

  &.completed {
    .onboarding-wizard-sidebar__text {
      text-decoration: line-through;
    }
  }
}

.onboarding-wizard-sidebar__icon {
  display: inline-flex;

  svg {
    color: $sc-positive;
  }
}
</style>

<style lang="scss">
// overwrite the styles coming from UiExpandablePanel
.onboarding-wizard-dropdown .ui-panel {
  border: none;
}

.onboarding-wizard-dropdown .ui-expandable-panel__toggle,
.onboarding-wizard-dropdown .ui-expandable-panel__body {
  padding: 0;
}

.onboarding-wizard-dropdown .ui-expandable-panel__toggle {
  font-weight: inherit;
}

.onboarding-wizard-dropdown .ui-expandable-panel__toggle:hover {
  text-decoration: none;
}
</style>
