/** @typedef {typeof state} ModuleState */
/** @typedef {import('@/features/subscription/types/subscriptions.types').BasicPlanInfo} BasicPlanInfo */
/** @typedef {import('@/types/models').User} User */
/** @typedef {import('@/types/models').Country} Country */
/** @typedef {import('@/types/models').Currency} Currency */

import { isBoolean, keyBy } from 'lodash-es'

import axios from '@/common/utils/axios'

import CountryApi from '@/common/api/country.api'
import SubscriptionsService from '@/features/subscription/services/subscriptions.service'
import UsersApi from '@/features/account/api/users.api'
import CurrencyApi from '@/common/api/currency.api'

import { PRICING_PILOT_GROUPS } from '@/features/subscription/constants'

import {
  CORE_SET_BASIC_PLANS_INFO,
  CORE_SET_COUNTRIES,
  CORE_SET_CURRENCIES,
  CORE_SET_DATA_DUMP,
  CORE_SET_GLOBAL_SETTINGS,
  CORE_SET_MOBILE_MENU_VISIBILITY,
  CORE_SET_PAGE_TITLE,
  CORE_SET_PAYMENT_INVOICE_INFO,
  CORE_SET_PAYMENT_WALL_TYPE,
  CORE_SET_SEARCH_QUERY_SHIPMENTS,
  CORE_SET_SIDEBAR_VISIBILITY,
  CORE_SET_STYLED_PORTAL,
  CORE_SET_UNSAVED_CHANGES,
  CORE_SET_USER,
  CORE_SET_USER_BETA_COMMUNITY,
  CORE_SET_VIEWED_TERMS_NOTIFICATION,
} from '@/common/stores/core/mutation.types'
import {
  CORE_ACCEPT_TERMS_AND_CONDITIONS,
  CORE_ACCOUNT_DELETE,
  CORE_ACCOUNT_UNFREEZE,
  CORE_CREATE_DATA_DUMP,
  CORE_DISPLAY_PAYMENT_MODAL,
  CORE_DISPLAY_PAYMENT_WALL,
  CORE_FETCH_BASIC_PLANS_INFO,
  CORE_FETCH_COUNTRIES,
  CORE_FETCH_COUNTRIES_LAZILY,
  CORE_FETCH_CURRENCIES,
  CORE_FETCH_CURRENCIES_LAZILY,
  CORE_FETCH_DATA_DUMP,
  CORE_FETCH_DEFINITIONS,
  CORE_FETCH_USER,
  CORE_SEND_EMAIL_VERIFICATION_MESSAGE,
  CORE_TOGGLE_MOBILE_MENU,
  CORE_TOGGLE_SIDEBAR_SETTINGS,
  CORE_UPDATE_PAGE_TITLE,
  CORE_UPDATE_SEARCH_QUERY_SHIPMENTS,
  CORE_UPDATE_SIDEBAR_SETTINGS,
  CORE_UPDATE_UNSAVED_CHANGES,
  CORE_UPDATE_USER,
  CORE_UPDATE_USER_BETA_COMMUNITY,
  CORE_VIEWED_TERMS_NOTIFICATION,
} from '@/common/stores/core/action.types'
import { SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS } from '@/features/addresses/stores/sender-addresses/action.types.js'

let isLoadingCurrencies = false

// Used to track the promises of lazy store actions.
// Enables multiple concurrent dispatches of a lazy action which will resolve at the same time
// because they await the same underlying promise.
let CORE_FETCH_COUNTRIES_LAZILY_PROMISE = null
let CORE_FETCH_CURRENCIES_LAZILY_PROMISE = null

const state = {
  /** @type {BasicPlanInfo[]} */ basicPlansInfo: undefined,
  /** @type {Array<Country>} */ countries: [],
  /** @type {Record<string, Country>} */ countriesByCode: {},
  /** @type {Record<number, Country>} */ countriesById: {},
  /** @type {Array<Currency>} */ currencies: [],
  currentRequestCount: 0,
  dataDump: null,
  globalSettings: {
    definitions: null,
    settings: null,
  },
  /** @type {String} */ paymentInvoiceId: undefined,
  paymentWallType: undefined,
  searchQuery: undefined,
  showMobileMenu: false,
  unsavedChanges: false,
  /** @type {User} */ user: undefined,
  viewedTermsNotification: false,
  pageTitle: '',
  sidebarSettings: null,
  showSidebarSettings: false,
  /** @type {Array<string>} */ userManageableBetaFeatures: [],
  lastPageRefresh: new Date(),
}

const getters = {
  allowedUserInvoiceCountries: (state, getters) => {
    return state.countries.filter(c => getters.definitions.users.allowed_user_invoice_countries.includes(c.iso_2))
  },

  allowedUserSenderCountries: (state, getters) => {
    return state.countries.filter(c => getters.definitions.users.allowed_user_sender_countries.includes(c.iso_2))
  },

  allowedUserPickupCountries: (state, getters) => {
    return state.countries.filter(c => getters.definitions.pickups.allowed_countries.includes(c.iso_2))
  },

  basicPlansInfo: (/** @type {ModuleState} */ state) => state.basicPlansInfo,

  countries: (/** @type {ModuleState} */ state) => state.countries,

  countryByISO2: (/** @type {ModuleState} */ state) => iso2 => state.countriesByCode[iso2],

  countryByID: (/** @type {ModuleState} */ state) => id => state.countriesById[id],

  countriesByShippingMethod: (_state, getters) => (shippingMethod, senderCountryId) => {
    const cache = (shippingMethod._countriesByShippingMethodCache =
      shippingMethod._countriesByShippingMethodCache || {})
    let ret = cache[senderCountryId]
    if (typeof ret === 'undefined') {
      // Countries are preloaded, so we can get away with a sync call
      const fromCountryIndex = 0
      const toCountryIndex = 1
      ret = cache[senderCountryId] = shippingMethod.countries
        .filter(c => c[fromCountryIndex] === senderCountryId)
        .flatMap(c => c[toCountryIndex])
        .map(id => getters.countryByID(id))
    }
    return ret
  },

  currentRequestCount: (/** @type {ModuleState} */ state) => state.currentRequestCount,

  currencies: (/** @type {ModuleState} */ state) => state.currencies,

  dataDump: (/** @type {ModuleState} */ state) => state.dataDump,

  definitions: (/** @type {ModuleState} */ state) => state.globalSettings.definitions,

  isHijacked: (/** @type {ModuleState} */ state) => {
    if (state.globalSettings.definitions) {
      return state.globalSettings.definitions.hijacked.is_hijacked
    } else {
      return false
    }
  },

  allLanguages: (_state, getters) => getters.settings.django.PANEL_LANGUAGES,

  lastPageRefresh: (/** @type {ModuleState} */ state) => state.lastPageRefresh,

  paymentInvoiceId: (/** @type {ModuleState} */ state) => state.paymentInvoiceId,

  paymentWallType: (/** @type {ModuleState} */ state) => state.paymentWallType,

  podId: (/** @type {ModuleState} */ state) => state.globalSettings.definitions.pod.id,

  searchQuery: (/** @type {ModuleState} */ state) => state.searchQuery,

  servicePointsMapUrl: (_state, getters) => getters.settings.django.SERVICEPOINTS_PUBLIC_URL,

  settings: (/** @type {ModuleState} */ state) => state.globalSettings.settings,

  showMobileMenu: (/** @type {ModuleState} */ state) => state.showMobileMenu,

  sortedCountries: (/** @type {ModuleState} */ state, getters) => {
    return state.countries
      .map(country => country)
      .sort((countryA, countryB) => countryA.name.localeCompare(countryB.name, getters.userLanguage))
  },

  user: (/** @type {ModuleState} */ state) => state.user,

  userCountry: (_state, getters) => getters.definitions.users.country,

  userLanguage: (_state, getters) => getters.definitions.users.language,

  userPlanGroup: (/** @type {ModuleState} */ state) => state.user.new_plan_group,

  userIsOnPricingPilot: (/** @type {ModuleState} */ state) => PRICING_PILOT_GROUPS.includes(state.user.new_plan_group),
  // TODO Add another getter to indicate the set of plans when available

  invoiceCurrency: (/** @type {ModuleState} */ state) => state.user.currency,

  viewedTermsNotification: (/** @type {ModuleState} */ state) => state.viewedTermsNotification,

  unsavedChanges: (/** @type {ModuleState} */ state) => state.unsavedChanges,

  userShouldSeeTermsAndConditions: (/** @type {ModuleState} */ state, getters) => {
    if (!state.user || !getters.definitions) {
      return false
    }

    if (getters.isHijacked) {
      return false
    }

    const hasAcceptedLatestPolicy = state.user.has_accepted_latest_policy
    const viewedTermsNotification = state.viewedTermsNotification

    if (hasAcceptedLatestPolicy || viewedTermsNotification) {
      return false
    }

    return true
  },

  pageTitle: (/** @type {ModuleState} */ state) => state.pageTitle,

  sidebarSettings: (/** @type {ModuleState} */ state) => state.sidebarSettings,

  showSidebarSettings: (/** @type {ModuleState} */ state) => state.showSidebarSettings,

  currency: (/** @type {ModuleState} */ state) => state.user.currency,

  userManageableBetaFeatures: (/** @type {ModuleState} */ state) => state.userManageableBetaFeatures,

  isStaffUser: (/** @type {ModuleState} */ state) => state.user?.is_staff,

  /** @type {(state: ModuleState) => (flag: string) => boolean} */
  hasBetaFeature(state) {
    return flag => state.user?.enabled_beta_features?.includes(flag) || false
  },

  userIsScheduledForDeletion: (/** @type {ModuleState} */ state) => Boolean(state.user?.delete_requested_at),

  userAccountIsOnHold: (/** @type {ModuleState} */ state) => Boolean(state.user?.is_account_on_hold),

  userHasOverdueInvoices: (/** @type {ModuleState} */ state) => Boolean(state.user?.has_overdue_invoices),
}

const actions = {
  async [CORE_ACCEPT_TERMS_AND_CONDITIONS]({ commit }) {
    const user = await UsersApi.acceptedTermsAndConditions()
    commit(CORE_SET_USER, user)
  },

  async [CORE_ACCOUNT_DELETE]({ commit }) {
    const user = await UsersApi.delete()
    commit(CORE_SET_USER, user)
  },

  async [CORE_ACCOUNT_UNFREEZE]({ commit }) {
    const user = await UsersApi.unfreeze()
    commit(CORE_SET_USER, user)
  },

  async [CORE_CREATE_DATA_DUMP]({ commit }) {
    const userData = await UsersApi.exportUserData()
    commit(CORE_SET_DATA_DUMP, userData)
  },

  [CORE_DISPLAY_PAYMENT_MODAL]({ commit }, invoiceId) {
    commit(CORE_SET_PAYMENT_INVOICE_INFO, invoiceId)
  },

  [CORE_DISPLAY_PAYMENT_WALL]({ commit }, value) {
    commit(CORE_SET_PAYMENT_WALL_TYPE, value)
  },

  async [CORE_FETCH_BASIC_PLANS_INFO]({ state, commit }, refresh) {
    if (state.basicPlansInfo && !refresh) {
      return Promise.resolve(state.basicPlansInfo)
    }
    const basicPlansInfo = await SubscriptionsService.getBasicPlansInfo()
    commit(CORE_SET_BASIC_PLANS_INFO, basicPlansInfo)
  },

  async [CORE_FETCH_COUNTRIES]({ commit }) {
    const response = await CountryApi.findAll()
    commit(CORE_SET_COUNTRIES, response.data)
  },

  async [CORE_FETCH_COUNTRIES_LAZILY]({ dispatch, state }) {
    if (state.countries.length === 0) {
      // If there isn’t already a fetch in progress, trigger one
      if (CORE_FETCH_COUNTRIES_LAZILY_PROMISE === null) {
        CORE_FETCH_COUNTRIES_LAZILY_PROMISE = dispatch(CORE_FETCH_COUNTRIES)
      }

      // Await the fetch promise and reset the promise reference
      await CORE_FETCH_COUNTRIES_LAZILY_PROMISE
      CORE_FETCH_COUNTRIES_LAZILY_PROMISE = null
    }
  },

  async [CORE_FETCH_DATA_DUMP]({ commit }) {
    let userData
    try {
      userData = await UsersApi.getUserData()
    } catch (e) {
      userData = null
    }
    commit(CORE_SET_DATA_DUMP, userData)
  },

  async [CORE_FETCH_USER]({ state, commit, dispatch }, refresh) {
    if (state.user && !refresh) {
      return Promise.resolve(state.user)
    }
    const user = await UsersApi.getMe()
    commit(CORE_SET_USER, user)
    await dispatch(SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS)
  },

  [CORE_SEND_EMAIL_VERIFICATION_MESSAGE]() {
    return UsersApi.resendVerificationEmail()
  },

  [CORE_TOGGLE_MOBILE_MENU]({ commit, state }, show) {
    commit(CORE_SET_MOBILE_MENU_VISIBILITY, isBoolean(show) ? show : !state.showMobileMenu)
  },

  async [CORE_FETCH_DEFINITIONS]({ commit }) {
    const response = await axios.get('/xhr/definitions')
    commit(CORE_SET_GLOBAL_SETTINGS, response.data)
  },

  async [CORE_UPDATE_USER]({ commit }, data) {
    const user = await UsersApi.update(data)
    commit(CORE_SET_USER, user)
  },

  [CORE_VIEWED_TERMS_NOTIFICATION]({ commit }) {
    commit(CORE_SET_VIEWED_TERMS_NOTIFICATION, true)
  },

  [CORE_UPDATE_UNSAVED_CHANGES]({ commit }, unsavedChanges) {
    commit(CORE_SET_UNSAVED_CHANGES, unsavedChanges)
  },

  [CORE_UPDATE_PAGE_TITLE]({ commit }, pageTitle) {
    commit(CORE_SET_PAGE_TITLE, pageTitle)
  },

  [CORE_TOGGLE_SIDEBAR_SETTINGS]({ commit, state }, show) {
    commit(CORE_SET_SIDEBAR_VISIBILITY, isBoolean(show) ? show : !state.showSidebarSettings)
  },

  [CORE_UPDATE_SIDEBAR_SETTINGS]({ commit }, sidebarSettings) {
    commit(CORE_UPDATE_SIDEBAR_SETTINGS, sidebarSettings)
  },

  [CORE_UPDATE_USER_BETA_COMMUNITY]({ commit }, isEnabled) {
    commit(CORE_SET_USER_BETA_COMMUNITY, isEnabled)
  },

  [CORE_UPDATE_SEARCH_QUERY_SHIPMENTS]({ commit }, searchQuery) {
    commit(CORE_SET_SEARCH_QUERY_SHIPMENTS, searchQuery)
  },

  async [CORE_FETCH_CURRENCIES_LAZILY]({ dispatch }) {
    if (state.currencies.length === 0) {
      // If there isn’t already a fetch in progress, trigger one
      if (CORE_FETCH_CURRENCIES_LAZILY_PROMISE === null) {
        CORE_FETCH_CURRENCIES_LAZILY_PROMISE = dispatch(CORE_FETCH_CURRENCIES)
      }

      // Await the fetch promise and reset the promise reference
      await CORE_FETCH_CURRENCIES_LAZILY_PROMISE
      CORE_FETCH_CURRENCIES_LAZILY_PROMISE = null
    }
  },

  async [CORE_FETCH_CURRENCIES]({ commit }) {
    if (isLoadingCurrencies) {
      return
    }

    let currencies = []
    try {
      isLoadingCurrencies = true
      currencies = await CurrencyApi.fetchCurrencies()
    } finally {
      commit(CORE_SET_CURRENCIES, currencies)
      isLoadingCurrencies = false
    }
  },
}

const mutations = {
  /**
   * @param {ModuleState} state
   * @param {BasicPlanInfo[]} basicPlansInfo
   */
  [CORE_SET_BASIC_PLANS_INFO](state, basicPlansInfo) {
    state.basicPlansInfo = basicPlansInfo
  },

  /**
   * @param {ModuleState} state
   * @param {Array<Currency>} currencies
   */
  [CORE_SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  },

  /**
   * Sets the ID of the invoice to be charged to the customer
   * @param {ModuleState} state
   * @param {String} invoiceId - the information of the invoice/initial payment that should be processed
   */
  [CORE_SET_PAYMENT_INVOICE_INFO](state, invoiceId) {
    state.paymentInvoiceId = invoiceId
  },

  /**
   * @param {ModuleState} state
   * @param {Array<Country>} countries
   */
  [CORE_SET_COUNTRIES](state, countries) {
    state.countries = countries
    state.countriesById = keyBy(countries, country => country.id)
    state.countriesByCode = keyBy(countries, country => country.iso_2)
  },

  [CORE_SET_DATA_DUMP](state, payload) {
    state.dataDump = payload
  },

  [CORE_SET_GLOBAL_SETTINGS](state, { definitions, settings }) {
    state.globalSettings = {
      definitions,
      settings,
    }
  },

  [CORE_SET_MOBILE_MENU_VISIBILITY](state, show) {
    state.showMobileMenu = show
  },

  [CORE_SET_SIDEBAR_VISIBILITY](state, show) {
    state.showSidebarSettings = show
  },

  [CORE_SET_PAYMENT_WALL_TYPE](state, value) {
    state.paymentWallType = value
  },

  [CORE_SET_SEARCH_QUERY_SHIPMENTS](state, value) {
    state.searchQuery = value
  },

  [CORE_SET_STYLED_PORTAL](state) {
    if (state.globalSettings.definitions?.returns) {
      state.globalSettings.definitions.returns.styled_portal = true
    }
  },

  /**
   * @param {ModuleState} state
   * @param {User} user
   */
  [CORE_SET_USER](state, user) {
    state.user = user
  },

  [CORE_SET_VIEWED_TERMS_NOTIFICATION](state, value) {
    state.viewedTermsNotification = value
  },

  [CORE_SET_UNSAVED_CHANGES](state, value) {
    state.unsavedChanges = value
  },

  [CORE_SET_PAGE_TITLE](state, pageTitle) {
    state.pageTitle = pageTitle
  },

  [CORE_UPDATE_SIDEBAR_SETTINGS](state, sidebarSettings) {
    state.sidebarSettings = sidebarSettings
  },

  /**
   * @param {ModuleState} state
   * @param {boolean} isEnabled
   */
  [CORE_SET_USER_BETA_COMMUNITY](state, isEnabled) {
    state.user = { ...state.user, ...{ beta_community: isEnabled } }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
