import Google from '@/common/utils/tracking/google'
import Sift from '@/common/utils/tracking/sift'
import Segment from '@/common/utils/tracking/segment'

import type { User } from '@/types/models'
import type { Definitions } from '@/types/definitions.models'

export async function installTrackingTools(user: User, definitions: Definitions): Promise<void> {
  if (!user || !definitions) {
    throw new Error('Missing required parameters to install user analytics tracking')
  }

  await Segment.install({
    isTrackingAllowed: definitions.enable_user_analytics === true,
    segmentKey: definitions.segment_js_source_write_key,
  })

  Google.install({
    isTrackingAllowed: definitions.enable_user_analytics === true,
    userId: user.id,
  })

  Sift.install({
    userId: user.id,
    accountId: definitions.sift_beacon_key,
    siftSessionId: definitions.login_tracking_beacon,
    isHijackedByAdmin: !!definitions?.hijacked?.is_hijacked,
    isStaff: !!user?.is_staff,
  })
}
