import tippy, { type Instance, type Placement } from 'tippy.js'
import type { Directive, DirectiveBinding } from 'vue'

type HTMLElementWithTippy = HTMLElement & { _tippy?: Instance }

function tooltipInit(el: HTMLElementWithTippy, binding: DirectiveBinding<string | null>) {
  const content = binding.value
  if (!content || el._tippy !== undefined) {
    return
  }

  const triggerList = []

  if (binding.modifiers.hover) triggerList.push('mouseenter')
  if (binding.modifiers.click) triggerList.push('click')
  if (binding.modifiers.focus) triggerList.push('focus')
  if (!triggerList.length) triggerList.push('mouseenter')

  tippy(el, {
    content,
    placement: binding.arg as Placement || 'top',
    trigger: triggerList.join(' '),
    allowHTML: Boolean(binding.modifiers.html),
  })
}

export default {
  beforeMount: tooltipInit,
  updated: tooltipInit,
  unmounted(el) {
    if (typeof el._tippy?.destroy === 'function') {
      el._tippy.destroy()
    }
  },
} as Directive<HTMLElementWithTippy, string | null>

// Note:
// Tippy defaults are set up in `src/utils/initialize-tippy.ts`.

// Usage:
// <button v-tooltip="'Tooltip text'">Hover me</button>
// <button v-tooltip.click="'Tooltip text'">Click me</button>
// <button v-tooltip:bottom="'Tooltip text'">Tooltip on the bottom</button>
