/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { DO_NOTHING_CTX } from '../context'
import type { CTX } from '../types/context.types'
import type { Parser } from '../types/parsers.types'
import type { AnyKey, AnyObject, StringOrArray } from '../types/type-helpers.types'

export const checkIsNumber = (data: unknown): data is number => typeof data === 'number' && !isNaN(data)

export const checkIsObject = <T extends AnyObject = AnyObject>(data: unknown): data is T =>
  !!data && typeof data === 'object' && !Array.isArray(data)

export const isEmptyObject = (data: unknown): data is Record<AnyKey, never> =>
  checkIsObject(data) && !Object.keys(data).length

export const checkIsValid = <T>(
  data: unknown,
  parser: Parser<T>,
  throwingCtx: CTX,
  onErrorCtx: CTX = DO_NOTHING_CTX,
): [boolean, T] => {
  try {
    return [true, parser.parse(data, throwingCtx)]
  } catch {
    // TODO: could try not parsing it again and just returning the data
    return [false, parser.parse(data, onErrorCtx)]
  }
}

export const checkIsNonempty = (data: StringOrArray) => !!data.length
