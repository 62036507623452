<template>
  <div
    class="onboarding-wizard-dropdown"
    data-test="onboarding-wizard-dropdown"
  >
    <span class="ui-text:color-muted">
      {{ t('Your profile is...') }}
    </span>
    <OWProgressBar
      size="medium"
      class="ui-margin-16--bottom"
    />
    <OWSidebarList :expandable="expandable" />
    <router-link
      class="ui-button ui-button--primary onboarding-wizard-dropdown__button"
      :to="{ name: ONBOARDING_WIZARD_ROUTE }"
    >
      {{ t('Go to next step') }}
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import OWProgressBar from '@/features/onboarding-wizard/components/OWProgressBar.vue'
import OWSidebarList from '@/features/onboarding-wizard/components/OWSidebarList.vue'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes'

const { t } = useI18n()

defineProps<{
  expandable?: boolean
}>()
</script>

<style lang="scss" scoped>
.onboarding-wizard-dropdown__button {
  display: block;
  text-align: center;
  margin-top: $sc-spacing-12;

  @include breakpoint-min-width(large) {
    margin-top: $sc-spacing-20;
  }
}
</style>
