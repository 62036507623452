/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Context } from '../context'
import type { CTX } from '../types/context.types'
import type { ParsersArray, ParserResult, Parser } from '../types/parsers.types'
import { generateErrorMessage, doNothing, getErrorMsg } from '../utils/helpers'

export class UnionParser<T extends ParsersArray> implements Parser<ParserResult<T[number]>> {
  readonly expected: string
  constructor(private readonly parsersArray: T) {
    this.expected = `(${parsersArray.map(({ expected }) => expected).join(' | ')})`
  }

  parse(data: unknown, ctx: CTX): ParserResult<T[number]> {
    const errors: string[] = []
    const { parsersArray } = this
    const l = parsersArray.length

    if (!l) {
      return data as ParserResult<T[number]>
    }

    // TODO: ?? creating inside the loop to preserve original context for each iteration
    const throwingCtx = new Context({
      originalCtx: ctx,
      onAssertionError: (params) => {
        throw new Error(generateErrorMessage(params))
      },
    })

    for (let i = 0; i < l; i++) {
      try {
        return parsersArray[i]!.parse(data, throwingCtx) as ParserResult<T[number]>
      } catch (e) {
        errors.push(getErrorMsg(e))
      }
    }

    // If we got here, all parsers have failed (since no return happened in the loop).
    // !!false to fail the assertion and avoid TS unreachable code error
    ctx.assert(
      !!false,
      `to match ${this.expected}`,
      `no match: [${errors.reduce((acc, curr, i) => {
        acc += `\n\tParser ${i + 1}: ${curr},`
        return acc
      }, '')}\n].\nFalling back to Parser 1`,
    )

    const doNothingCtx = new Context({
      originalCtx: ctx,
      onAssertionError: doNothing,
    })

    // fallback to one of the parsers, while ignoring errors
    // (to still perform the expected parsing/coercing, because our error could be not that breaking)
    return parsersArray[0]!.parse(data, doNothingCtx) as ParserResult<T[number]>
  }
}

/**
 * `union()` accepts any of the types passed to it and fails if none match.
 *
 * Works for any parsers and value types.
 *
 * ```ts
 * union(string(), number()) // valid: 1, 'str', invalid: other types
 * ```
 */
export const union = <First extends Parser, Others extends ParsersArray>(
  ...parsersArray: [firstParser: First, ...otherParsers: Others]
) => new UnionParser(parsersArray)
