import * as Sentry from '@sentry/vue'
import { AxiosError, CanceledError, isAxiosError } from 'axios'

const showConsoleErrors = import.meta.env.VUE_APP_SHOULD_LOG_TO_CONSOLE === 'yes'

/**
 * Decide which errors should be reported to Sentry. Try to ignore unactionable errors (e.g. 403 status code).
 * This function should never be used directly - always use `logError` instead.
 */
function shouldReportError(error: unknown): boolean {
  if (isAxiosError(error)) {
    // Ignore unactionable API response codes:
    const errorCodesToIgnore = [401, 403, 404, 429]
    if (error.response?.status && errorCodesToIgnore.includes(error.response?.status)) {
      return false
    }

    // Ignore aborted requests:
    const axiosErrorTypesToIgnore = [AxiosError.ECONNABORTED]
    if (error.code && axiosErrorTypesToIgnore.includes(error.code)) {
      return false
    }

    // Ignore cancelled requests:
    if (error instanceof CanceledError) {
      return false
    }
  }

  return true
}

/**
 * Handle any unhandled errors thrown by components.
 *
 * In development environment, the error will be logged to the console.
 *
 * In a production environment or an environment where Sentry is enabled, the error will be sent to Sentry.
 */
export const globalErrorHandler = (error: unknown): void => {
  logError(error)
}

type LogErrorParams<E> = {
  additionalData?: Parameters<typeof Sentry.captureException>[1]
  formatError?: (e: E) => unknown
}

/**
 * Log errors to Sentry. Use wherever you would like to have observability on errors (usually in a `catch` clause).
 * You may also pass additional data to Sentry, and a formatError function.
 *
 * This function is also called in the Vue application's global error handler, for any errors that are thrown out
 * of their component.
 *
 * Note: Non-actionable errors (e.g. `403` error responses) will not be sent to Sentry (see `shouldReportError` in
 * `error-handlers.ts` for the full list of non-actionable errors).
 */
export const logError = <E>(
  error: E,
  { additionalData, formatError }: LogErrorParams<E> = {},
) => {
  const formattedError = formatError ? formatError(error) : error
  if (showConsoleErrors) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  if (shouldReportError(error)) {
    /* eslint-disable-next-line no-restricted-properties */
    Sentry.captureException(formattedError, additionalData)
  }
}

/**
 * Registers fullscreen error overlay for passed error
 */
export const showFullscreenErrorOverlay = (err: Error) => {
  const ErrorOverlay = customElements.get('vite-error-overlay')
  if (!ErrorOverlay) {
    return
  }
  const overlay = new ErrorOverlay(err)
  document.body.appendChild(overlay)
}
