import { computed, ref } from 'vue'
import { fetchUnreadMessagesCount } from '@/features/whatsapp-conversations/api/whatsapp-conversations.api'

// one minute
const INTERVAL_MS = 1000 * 60

export const useUnreadCounterPolling = () => {
  const unreadMessagesCount = ref(0)
  const unreadMessagesCounterText = computed(() => {
    const count = unreadMessagesCount.value
    if (!count) {
      return ''
    }
    // we never show anything above 100, and our endpoint never returns >100
    return count >= 100 ? '99+' : `${count}`
  })

  const timerId = ref<ReturnType<typeof setInterval>>()

  const updateCounter = async () => {
    try {
      unreadMessagesCount.value = await fetchUnreadMessagesCount()
    } catch (e) {
      // TODO: uncomment when endpoint is ready
      // logError(e)
    }
  }

  const startPolling = () => {
    clearInterval(timerId.value)
    updateCounter()
    timerId.value = setInterval(updateCounter, INTERVAL_MS)
  }

  const stopPolling = () => clearInterval(timerId.value)

  return { startPolling, stopPolling, unreadMessagesCount, unreadMessagesCounterText }
}
