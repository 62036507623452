import { Context } from '../context'
import type { CTX } from '../types/context.types'
import type { Parser, ParserResult, ParsersArray } from '../types/parsers.types'
import { generateShortErrorMessage, getTruncatedErrorsText, toReadableTypeOf } from '../utils/helpers'
import { UnionParser, union } from './union'

class ArrayParser<T extends Parser> implements Parser<Array<ParserResult<T>>> {
  constructor(readonly expected: string, readonly parser: T) {}

  parse(data: unknown, ctx: CTX): Array<ParserResult<T>> {
    const isArray = Array.isArray(data)
    ctx.assert(isArray, this.expected, toReadableTypeOf(data))
    // ctx.assert does not have to throw error (can just log it), so we have to re-check conditions ourselves
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!isArray) {
      return data as Array<ParserResult<T>>
    }
    const result: unknown[] = []
    const { parser } = this

    const errors: Array<string> = []
    let errorCounter = 0

    const populateErrorsCtx = new Context({
      originalCtx: ctx,
      onAssertionError: (params) => {
        // no need to generate messages if we wont display them
        errorCounter < ctx.maxErrorsInOneMsg && errors.push(generateShortErrorMessage(params))
        errorCounter++
      },
    })

    for (let i = 0, l = data.length; i < l; i++) {
      result[i] = populateErrorsCtx.deepen(i, () => parser.parse(data[i], populateErrorsCtx))
    }
    if (!errorCounter) {
      return result as Array<ParserResult<T>>
    }

    // If we got here, some parsers have failed
    // !!false to fail the assertion and avoid TS unreachable code error
    ctx.assert(
      !!false,
      `no errors in ${this.expected}`,
      errorCounter === 1
        ? `[${errors[0]}]`
        : `${errorCounter} errors: [${getTruncatedErrorsText(errors, ctx.maxErrorsInOneMsg, errorCounter)}]`,
    )

    return result as Array<ParserResult<T>>
  }
}

/**
 * `array(...)` parser accepts an array of provided values or `[]`.
 * If you do not want accept empty arrays, use `nonempty(array(...))`
 *
 * Can receive 1 or more types. If received > 1 type - infers union.
 * ```ts
 * // Examples:
 *
 * // string[]
 * array(string()) // valid: [] or ['']
 * // nonempty string[]
 * nonempty(array(string()) // valid: [''], invalid: []
 * //  Array<string | number>
 * array(string(), number()) // valid: [], [1], ['s'], [1, 's']
 * // string[] | number[]
 * union(array(string()), array(number())) // valid: [], [1], ['s'], invalid: ['s', 1]
 * ```
 */
export const array = <First extends Parser, Others extends ParsersArray | never[]>(
  firstParser: First,
  ...otherParsers: Others
) => {
  // to not repeat this huge thing again
  type ReturnType = Others extends never[] ? ArrayParser<First> : ArrayParser<UnionParser<[First, ...Others]>>

  if (!otherParsers.length) {
    return new ArrayParser(`Array<${firstParser.expected}>`, firstParser) as ReturnType
  }
  const unionParser = union(firstParser, ...otherParsers)
  return new ArrayParser(`Array<${unionParser.expected}>`, unionParser) as ReturnType
}
