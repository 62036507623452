type AnyFunction = (..._args: any[]) => any
/**
 * Native debounced version
 * @param {Function} func - The original function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @returns {Function} - The debounced function.
 */
export function debounce<T extends AnyFunction>(func: T, delay: number): () => void {
  let timeoutId: ReturnType<typeof setTimeout>

  // eslint-disable-next-line no-unused-vars
  return function (this: unknown, ...args: Parameters<T>): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const lastThis = this
    clearTimeout(timeoutId)

    function debouncedCall() {
      func.apply(lastThis, args)
    }

    timeoutId = setTimeout(debouncedCall, delay)
  }
}
