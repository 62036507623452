import DateTime from '@/common/utils/date-time'

import i18n from '@/application/i18n/i18n'
import type { Dayjs } from 'dayjs'
import type { ItemQuantityFilter } from '@/common/types/filter.types'

export function currency(value: number | string, places?: number, currency?: string): string {
  const currencyFormatOptions = { ...i18n.numberFormats[i18n.locale].currency }

  if (currency) {
    currencyFormatOptions.currency = currency
  }

  if (typeof places === 'number') {
    currencyFormatOptions.minimumFractionDigits = places
  }

  const numberCurrencyFormat = new Intl.NumberFormat(i18n.locale, currencyFormatOptions)
  let numericValue: number
  if (typeof value === 'string') {
    numericValue = parseFloat(value)
  } else {
    numericValue = value
  }
  return numberCurrencyFormat.format(numericValue)
}

/**
 * Filters the POD ID from the invoice reference
 */
export function invoiceRef(invoiceId: string): string {
  const idRegExp = /[\dA-Z-]+-(?<invoice>[\d]{2}-[A-Z]{2}[\d]{7})$/g
  const matches = [...invoiceId.matchAll(idRegExp)]
  return matches[0]?.groups?.invoice || invoiceId
}

export function number(value: number | bigint, places?: number) {
  const numberFormatOptions = { ...i18n.numberFormats[i18n.locale].number }
  if (typeof places === 'number') {
    numberFormatOptions.minimumFractionDigits = places
  }

  const numberFormat = new Intl.NumberFormat(i18n.locale, numberFormatOptions)
  return numberFormat.format(value)
}

export const shortDate = (date: Date | string | number | Dayjs | null) => DateTime.format('L', date)
export const shortDateMonthText = (date: Date | string | number | Dayjs) => DateTime.format('MMM D, Y LT', date)
export const shortDatetime = (date: Date | string | number | Dayjs) => DateTime.format('L LT', date)
export const longDate = (date: Date | string | number | Dayjs) => DateTime.format('LLL', date)
export const timeToNow = (date: Date | string | number | Dayjs) => DateTime.toNow(date)
export const timeFromNow = (date: Date | string | number | Dayjs) => DateTime.fromNow(date)
export const timeToCalendar = (date: Date | string | number | Dayjs) => DateTime.calendar(date)

type ItemQuantityOperators = ItemQuantityFilter['operator']
export const getValidItemQuantityOperator = (str: string | undefined): ItemQuantityOperators | undefined => {
  if (!str || (str !== 'gt' && str !== 'lt' && str !== 'eq')) {
    return undefined
  }

  return str
}
