<template>
  <header
    class="top-menu"
    :class="{ 'top-menu__with_banner': useNewNavigation }"
  >
    <div class="top-menu__container">
      <transition
        name="overlayfade"
        mode="out-in"
      >
        <div
          v-if="slideOutMenuOpen"
          class="top-menu__overlay"
          @click="toggleMenu(false)"
        />
      </transition>

      <a
        class="slide-out-menu__logo slide-out-menu__logo--mobile"
        href="/"
      >
        <UiLogo icon-only />
        <span class="sr-only">{{ t('global.navigation.header.logo') }}</span>
      </a>

      <button
        class="top-menu__toggle"
        @click="toggleMenu(true)"
      >
        <Fa
          :icon="faBars"
          class="top-menu__toggle-icon ui-icon--large"
        />
      </button>

      <div
        :class="{ 'slide-out-menu__wrapper--open': slideOutMenuOpen }"
        class="slide-out-menu__wrapper"
        @click="toggleMenu(false)"
      >
        <a
          class="slide-out-menu__logo"
          href="/"
        >
          <UiLogo />
          <span class="sr-only">{{ t('global.navigation.header.logo') }}</span>
        </a>

        <OWDropdown
          v-if="showOnboardingWizard && !isDesktopView"
          class="onboarding-wizard-dropdown--in-slide-out-menu"
          :expandable="!isDesktopView"
        />

        <nav
          class="slide-out-menu__nav"
          :aria-label="t('global.navigation.main')"
        >
          <ul class="slide-out-menu__nav-list">
            <AppTopMenuItem
              v-if="permissions.view_parcels || permissions.manage_shipping || permissions.process_orders"
              id="smoke-test-shipping"
              module-path="shipping"
              data-appcues="shipping"
              :href="shippingHref"
              :current-route-name="currentRouteName"
              alternative-event-name="incoming-orders-reload"
            >
              {{ t('Shipping') }}
            </AppTopMenuItem>

            <AppTopMenuItem
              v-if="user && user.features && user.features.pack_and_go && permissions.process_orders"
              id="packgo"
              module-path="packgo"
              data-appcues="packgo"
              :href="{ name: PACKGO_QUEUE_ROUTE }"
              :current-route-name="currentRouteName"
              alternative-event-name="packgo-queue-reload"
            >
              {{ t('Pack & Go') }}
            </AppTopMenuItem>

            <AppTopMenuItem
              v-if="user.is_returns_enabled === true && permissions.manage_returns"
              id="returns"
              module-path="returns"
              data-appcues="returns"
              :href="{ name: RETURNS_LIST_ROUTE, query: { state: hasReturnTabsBeta } }"
            >
              {{ t('Returns') }}
            </AppTopMenuItem>

            <AppTopMenuItem
              id="pickups"
              module-path="pickups"
              data-appcues="pickups"
              :href="{ name: PICKUPS_ROUTE }"
            >
              {{ t('Pickups') }}
            </AppTopMenuItem>

            <AppTopMenuItem
              id="rates"
              module-path="settings/rates"
              data-appcues="rates"
              :href="{ name: SETTINGS_RATES_ROUTE }"
            >
              {{ t('Rates') }}
            </AppTopMenuItem>
          </ul>
        </nav>

        <nav
          class="slide-out-menu__nav slide-out-menu__nav--side"
          :aria-label="t('global.navigation.tools')"
        >
          <ul class="slide-out-menu__nav-list">
            <AppTopMenuItem
              id="appstore"
              module-path="appstore"
              data-appcues="appstore"
              :target="'_blank'"
              :rel="'noopener noreferrer'"
              :external-url="true"
              :href="appStoreLink"
            >
              {{ t('main.navigation.appstore.title') }}
            </AppTopMenuItem>

            <AppTopMenuItem
              v-if="hasConversationsBetaFeature"
              id="conversations"
              :icon="true"
              :tooltip="isDesktopView"
              :tooltip-title="t('Conversations')"
              module-path="conversations"
              data-appcues="conversations"
              :href="{ name: WHATSAPP_CONVERSATIONS_ROUTE }"
            >
              <span
                v-if="unreadMessagesCounterText"
                class="top-menu__conversations-counter"
              >
                {{ unreadMessagesCounterText }}
              </span>
              <Fa
                class="top-menu__icon ui-icon--large"
                :icon="faComment"
              />
              <span class="sr-only">{{ t('Conversations') }}</span>
            </AppTopMenuItem>

            <AppTopMenuItem
              v-if="permissions.view_analytics"
              id="analytics"
              :icon="true"
              :tooltip="isDesktopView"
              :tooltip-title="t('Analytics')"
              module-path="analytics"
              data-appcues="analytics"
              :href="{ name: ANALYTICS_SHIPPING_ROUTE }"
            >
              <Fa
                class="top-menu__icon ui-icon--large"
                :icon="faChartBar"
              />

              <span class="sr-only">{{ t('Analytics') }}</span>
            </AppTopMenuItem>

            <AppTopMenuItem
              id="settings"
              :icon="true"
              :tooltip="isDesktopView"
              :tooltip-title="t('Settings')"
              :show-warning="integrationsWarning"
              module-path="settings"
              data-appcues="settings"
              :href="settingsHref"
            >
              <Fa
                class="top-menu__icon ui-icon--large"
                :icon="faCog"
              />

              <span class="sr-only">{{ t('Settings') }}</span>
            </AppTopMenuItem>

            <AppTopMenuItem
              v-if="permissions.manage_tickets"
              id="support"
              :icon="true"
              :tooltip="isDesktopView"
              :tooltip-title="t('Support')"
              module-path="support"
              data-appcues="support"
              :href="{ name: SUPPORT_ROUTE }"
            >
              <Fa
                class="top-menu__icon ui-icon--large"
                :icon="faQuestionCircle"
              />

              <span class="sr-only">{{ t('Support') }}</span>
            </AppTopMenuItem>

            <AppTopMenuNotifications
              id="notifications"
              data-appcues="notifications"
              :tooltip="isDesktopView"
            />

            <AppTopMenuItem
              id="signout"
              :icon="true"
              :tooltip="isDesktopView"
              :tooltip-title="t('Sign out')"
              module-path="accounts"
              :href="logoutURL"
            >
              <Fa
                :icon="faPowerOff"
                class="top-menu__icon slide-out-menu__icon--dark ui-icon--large"
              />

              <span class="sr-only">{{ t('Sign out') }}</span>
            </AppTopMenuItem>
          </ul>
        </nav>
      </div>
      <OWProfile
        v-if="showOnboardingWizard"
        :is-desktop-view="isDesktopView"
      />
    </div>
    <div
      v-if="useNewNavigation"
      id="top-navigation__widget--switch-navigation"
      class="top-navigation__widget--switch-navigation"
    >
      <Fa
        :icon="faStar"
        class="top-navigation-item__icon ui-margin-12--right"
      />
      <p>
        <strong
          class="ui-margin-8--right"
        >{{ t('top_navigation.widget.title') }}</strong>  {{ t('top_navigation.widget.text') }}
        <button
          class="top-navigation__widget--switch-navigation--link ui-link ui-margin-8--left"
          type="button"
          @click="toggleNavigation"
        >
          {{ t('top_navigation.widget_text_button') }}
        </button>
      </p>
    </div>
  </header>
</template>

<script setup lang="ts">
import AppTopMenuItem from '@/application/components/old-navigation/AppTopMenuItem.vue'
import AppTopMenuNotifications from '@/application/components/old-navigation/AppTopMenuNotifications.vue'
import { getLogoutURL } from '@/common/utils/backend'
import { SETTINGS_ACCOUNT_ROUTE } from '@/features/account/routes'
import { ANALYTICS_SHIPPING_ROUTE } from '@/features/analytics/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import OWDropdown from '@/features/onboarding-wizard/components/OWDropdown.vue'
import OWProfile from '@/features/onboarding-wizard/components/OWProfile.vue'
import { PACKGO_QUEUE_ROUTE } from '@/features/packgo/routes'
import { PICKUPS_ROUTE } from '@/features/pickups/routes'
import { SETTINGS_RATES_ROUTE } from '@/features/rates/routes'
import { RETURN_BETA_FEATURES } from '@/features/returns/beta-features'
import { RETURNS_LIST_ROUTE } from '@/features/returns/routes'
import { ReturnListTab } from '@/features/returns/types/enums'
import { SHIPPING_CREATED_LABELS_ROUTE } from '@/features/shipment-tabs/routes'
import { SUPPORT_ROUTE } from '@/features/support/routes'
import { CONVERSATIONS_BETA_FEATURE } from '@/features/whatsapp-conversations/beta-features'
import { WHATSAPP_CONVERSATIONS_ROUTE } from '@/features/whatsapp-conversations/routes'
import { useConversationsStore } from '@/features/whatsapp-conversations/stores'
import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { faBars, faChartBar, faCog, faPowerOff, faQuestionCircle, faStar } from '@fortawesome/pro-solid-svg-icons'
import { UiLogo } from '@sendcloud/ui-library'
import { debounce } from 'lodash-es'
import { computed, inject, onMounted, ref, watch } from 'vue'
import Fa from 'vue-fa'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()

const slideOutMenuOpen = ref(false)
const isDesktopView = ref(false)
const currentRouteName = ref('')

defineProps<{
  useNewNavigation: boolean
}>()

type ToggleNavigation = (payload: MouseEvent) => void
const toggleNavigation = inject<ToggleNavigation>('toggleNavigation')

const { t } = useI18n()

const user = computed(() => store.getters.user)
const integrationsWarning = computed(() => store.getters.integrationsWarning)
const showOnboardingWizard = computed(() => store.getters.showOnboardingWizard)
const conversationsStore = useConversationsStore()
const unreadMessagesCounterText = computed(() => conversationsStore.unreadMessagesCounterText)

const permissions = computed(() => user.value?.permissions ?? {})

const shippingHref = computed(() => {
  return { name: permissions.value.process_orders ? SHIPPING_INCOMING_ORDERS_ROUTE : SHIPPING_CREATED_LABELS_ROUTE }
})

const hasReturnTabsBeta = computed(() => {
  return store.getters.hasBetaFeature(RETURN_BETA_FEATURES.RETURN_TABS)
    ? ReturnListTab.IN_TRANSIT
    : ReturnListTab.INCOMING
})
const hasConversationsBetaFeature = computed<boolean>(() => store.getters.hasBetaFeature(CONVERSATIONS_BETA_FEATURE))

const settingsHref = computed(() => {
  return permissions.value.manage_account || permissions.value.delete_account || permissions.value.export_data
    ? { name: SETTINGS_ACCOUNT_ROUTE }
    : import.meta.env.VUE_APP_ACCOUNTS_BASE_URL
})

const appStoreLink = 'https://app-store.sendcloud.com/'

const logoutURL = getLogoutURL()

const toggleMenu = (openState: boolean) => {
  slideOutMenuOpen.value = openState
}

watch(slideOutMenuOpen, (newValue) => {
  if (newValue) {
    document.body.classList.add('topbar__body--no-scroll')
  } else {
    document.body.classList.remove('topbar__body--no-scroll')
  }
})

watch(route, (to) => {
  currentRouteName.value = to.name as string
})

onMounted(() => {
  currentRouteName.value = route.name as string

  if (window.matchMedia('(min-width: 992px)').matches) {
    isDesktopView.value = true
  }

  window.addEventListener(
    'resize',
    debounce(() => {
      if (window.matchMedia('(min-width: 992px)').matches) {
        isDesktopView.value = true
      } else {
        isDesktopView.value = false
      }
    }, 500),
  )
})
</script>

<style lang="scss" scoped>
$animation-speed: 0.25s;

@keyframes overlayfade {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.4;
  }
}

.overlayfade-enter-active {
  animation: overlayfade $animation-speed ease;
}

.overlayfade-leave-active {
  animation: overlayfade $animation-speed ease reverse;
}

.top-menu {
  height: 50px;
  margin-bottom: 20px;

  @include breakpoint-min-width(medium) {
    height: auto;
    margin-bottom: 0;
  }
}

.top-menu__container {
  align-items: center;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 102;
  background-color: #fff;
  box-shadow: 0 4px 10px rgb(17 40 87 / 7%);
  height: 50px;
  margin-bottom: $sc-spacing-16;

  @include breakpoint-min-width(medium) {
    position: relative;
  }

  @include breakpoint-max-width(large) {
    justify-content: space-between;
  }

  @include breakpoint-min-width(large) {
    height: 80px;
    padding-left: $sc-spacing-16;
    margin-bottom: $sc-spacing-32;
  }

  @include breakpoint-min-width(1460px) {
    padding-left: 50px;
    padding-right: $sc-spacing-32;
  }
}

.slide-out-menu__wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px $sc-spacing-20;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition: transform $animation-speed ease;
  width: 240px;
  z-index: 10;
  overflow-y: auto;

  @include breakpoint-min-width(large) {
    align-items: center;
    flex-direction: row;
    padding: 0;
    position: static;
    transform: none;
    width: 100%;
    overflow-y: unset;
  }
}

.top-menu__conversations-counter {
  position: absolute;
  right: $sc-spacing-8;
  bottom: 50%;
  font-size: 0.75rem;
  line-height: 1.1875;
  font-weight: 600;
  height: 20px;
  min-width: 20px;
  padding: 0 $sc-spacing-4;
  background-color: $sc-danger-80;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $sc-white;
}

.slide-out-menu__wrapper--open {
  transform: translate3d(0, 0, 0);
}

.top-menu__overlay {
  background-color: black;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  @include breakpoint-min-width(large) {
    display: none;
  }
}

.top-menu__toggle {
  align-items: center;
  background-color: $sc-informative;
  border: 0;
  color: #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 0;
  width: 50px;

  // push the button to the right
  order: 1;

  @include breakpoint-min-width(large) {
    display: none;
  }
}

.top-menu__toggle-icon {
  font-size: 26px;
}

.slide-out-menu__logo {
  margin-right: 10px;
  width: 150px;

  @include breakpoint-min-width(large) {
    margin-bottom: 0;
    margin-right: 20px;
    width: 200px;
  }

  svg {
    display: block;
    max-height: 25px;

    @include breakpoint-min-width(large) {
      max-height: unset;
    }
  }
}

.slide-out-menu__wrapper .slide-out-menu__logo {
  @include breakpoint-range(large, extra-large) {
    display: none;
  }
}

.slide-out-menu__logo--mobile {
  margin-left: 15px;
  width: 40px;

  @include breakpoint-min-width(extra-large) {
    display: none;
  }
}

.slide-out-menu__nav {
  margin: 20px -10px 0 -10px;

  @include breakpoint-min-width(large) {
    height: 100%;
    margin: 0;
  }
}

.slide-out-menu__nav--side {
  @include breakpoint-max-width(large) {
    margin-top: auto;

    .top-menu__icon {
      display: none;
    }

    .sr-only {
      clip: auto;
      height: auto;
      margin: 0;
      position: static;
      width: auto;
      overflow: visible;
    }

    .top-menu__conversations-counter {
      bottom: unset;
      align-self: center;
    }
  }

  @include breakpoint-min-width(large) {
    margin-left: auto;
  }
}

.slide-out-menu__nav-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include breakpoint-min-width(large) {
    flex-direction: row;
  }
}

.slide-out-menu__icon--dark {
  color: $sc-dark-blue;

  ~ * {
    color: $sc-dark-blue;
  }
}

.onboarding-wizard-dropdown--in-slide-out-menu {
  margin-top: $sc-spacing-20;
  padding-bottom: $sc-spacing-24;
  border-bottom: 1px solid $sc-mid-grey;

  @include breakpoint-min-width(large) {
    display: none;
  }
}

.top-navigation__widget--switch-navigation {
  width: 100%;
  align-items: center;
  text-align: center;
  background: #DDDFFA;
  color: $sc-dark-blue;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: $sc-spacing-8;

  @include breakpoint-min-width(medium) {
    margin-top: -16px;
  }

  @include breakpoint-min-width(large) {
    margin-top: -32px;
  }
}

.top-navigation__widget--switch-navigation p {
  padding: 0;
  margin: 0;
}

.top-navigation__widget--switch-navigation--link {
  color: $sc-informative;
  text-decoration: underline;
  border: none;
}

.top-menu__with_banner {
  margin-bottom: 110px;

  @include breakpoint-min-width(extra-small) {
    margin-bottom: 90px;
  }

  @include breakpoint-min-width(medium) {
    margin-bottom: 50px;
  }

  @include breakpoint-min-width(large) {
    margin-bottom: 24px;
  }
}
</style>

<style lang="scss">
.topbar__body--no-scroll {
  overflow: hidden;

  @include breakpoint-min-width(large) {
    overflow: unset;
  }
}

// align with styles of .slide-out-menu__nav
.onboarding-wizard-dropdown--in-slide-out-menu .onboarding-wizard-sidebar__nav,
.onboarding-wizard-dropdown--in-slide-out-menu .onboarding-wizard-sidebar__section--title {
  margin-left: -10px;
  margin-right: -10px;
}
</style>
