<template>
  <div
    v-if="incidents.length > 0"
    class="statuspage-widget"
  >
    <transition-group
      name="sc-fade"
      tag="div"
    >
      <StatuspageToast
        v-for="incident in incidents"
        :key="incident.id"
        :incident="incident"
        @close-incident="closeIncident"
      />
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

import { useDateTime } from '@/common/utils/use-date-time'
import poll from '@/common/utils/poll'

import { getIncidents } from '@/features/statuspage-widget/api/statuspage.api'

import { SHIPPING_FETCH_CARRIERS_LAZILY } from '@/features/shipment-tabs/stores/shipping/action.types.js'

import {
  CARRIER_CODE_MAP,
  HOURS_TO_SHOW_RESOLVED_STATUS_FOR,
  MAX_NUMBER_OF_INCIDENTS_TO_SHOW,
  POLL_DELAY_IN_MINUTES,
} from '@/features/statuspage-widget/constants'

import type { Carrier } from '@/features/carriers/types/carrier.types'
import type { Settings, User } from '@/types/models'
import type { Incident, StatuspageIncident, StatuspageStatus } from '@/features/statuspage-widget/types/statuspage'

const StatuspageToast = defineAsyncComponent(() => import('./StatuspageToast.vue'))

const store = useStore()
const { getTimeFromNow } = useDateTime()

const currentDate = new Date()
const incidentIsOldDate = getDateTwoDaysAgo(currentDate)

const incidents = ref<Incident[]>([])
const dismissedIncidentStatuses = ref<Map<string, StatuspageStatus> | null>(null)

const carriers = computed<Carrier[]>(() => store.getters.carriers)
const settings = computed<Settings>(() => store.getters.settings)
const user = computed<User>(() => store.getters.user)

const enabledCarrierCodes = computed<string[]>(() => {
  return carriers.value.filter(carrier => carrier.is_enabled).map(carrier => carrier.code)
})

const userJoinedDate = computed<Date>(() => {
  return new Date(user.value.date_joined)
})

const storageKey = computed<string>(() => {
  return `dismissed-statuspage-incidents-${user.value.id}`
})

onBeforeMount(async () => {
  await store.dispatch(SHIPPING_FETCH_CARRIERS_LAZILY)
  initializeDismissedIncidents()

  if (!settings.value.django.IS_RUNNING_E2E) {
    pollStatuspageIncidents()
  }
})

function getDateTwoDaysAgo(currentDate: Date): Date {
  return new Date(currentDate.setDate(currentDate.getDate() - 2))
}

/**
 * Removes an incident from the page.
 */
function closeIncident(incidentToClose: Incident) {
  dismissIncident(incidentToClose)

  const deleteIndex = incidents.value.findIndex(incident => incident.id === incidentToClose.id)
  if (deleteIndex > -1) {
    incidents.value.splice(deleteIndex, 1)
  }
}

/**
 * Persistently dismisses an incident by storing its ID in localStorage.
 * Upon populating the page with new incidents, dismissed ones are ignored.
 */
function dismissIncident(incident: Incident) {
  dismissedIncidentStatuses.value?.set(incident.id, incident.status)

  if (dismissedIncidentStatuses.value) {
    storeDismissedIncidents(Array.from(dismissedIncidentStatuses.value.entries()))
  }
}

/**
 * Retrieves a list of dismissed incident IDs and their statuses from localStorage.
 *
 * Intentionally doesn’t use our `getVariable` method due to a [bug][SC-18713]
 * where notifications would get un-dismissed upon switching accounts.
 *
 * [SC-18713]: https://sendcloud.atlassian.net/browse/SC-18713
 */
function getStoredDismissedIncidents(): [string, StatuspageStatus][] {
  let storagePayload

  try {
    storagePayload = window.localStorage.getItem(storageKey.value)
  } catch (error) {}

  if (typeof storagePayload === 'string') {
    return JSON.parse(storagePayload)
  }

  return []
}

/**
 * Reads in a list of dismissed incident IDs from localStorage.
 * The list is used to not show incidents to users who dismissed (closed) them.
 */
function initializeDismissedIncidents(): void {
  dismissedIncidentStatuses.value = new Map(getStoredDismissedIncidents())
}

/**
 * Comparison function for Statuspage statuses. Returns true for “older” or equal statuses.
 *
 * Statuses are ordered like this from oldest to newest:
 *
 * - investigating
 * - identified
 * - monitoring
 * - resolved
 * - postmortem
 */
function isStatusOlderOrEqual(statusA: StatuspageStatus, statusB: StatuspageStatus): boolean {
  const order = ['investigating', 'identified', 'monitoring', 'resolved', 'postmortem']
  return order.indexOf(statusA) <= order.indexOf(statusB)
}

/**
 * Checks if a user is affected by an incident.
 *
 * An incident affects a user if:
 *
 * - the incident doesn’t list any components
 * - the incident lists at least one component that is not carrier-specific
 * - the incident lists at least one carrier-specific component
 *   including a carrier that is enabled by the user
 */
function isUserAffectedByIncident(incident: StatuspageIncident): boolean {
  // If the incident doesn’t have any affected components,
  // we assume the incident is more general and thus, the user is potentially affected.
  if (incident.components.length === 0) {
    return true
  }

  const carrierPrefix = 'Carriers - '

  const isNotCarrierSpecific = incident.incident_updates.some((incidentUpdate) => {
    if (!incidentUpdate.affected_components) {
      return true
    }

    return incidentUpdate.affected_components.some((component) => {
      return !component.name.startsWith(carrierPrefix)
    })
  })

  if (isNotCarrierSpecific) {
    return true
  }

  // Get names of affected components which are carrier-related
  const affectedComponentNames: Set<string> = new Set()
  for (const incidentUpdate of incident.incident_updates) {
    incidentUpdate.affected_components.forEach((component) => {
      const prefixPos = component.name.indexOf(carrierPrefix)

      if (prefixPos === 0) {
        const componentName = component.name.substring(prefixPos + carrierPrefix.length).trim()
        affectedComponentNames.add(componentName)
      }
    })
  }

  for (const componentName of affectedComponentNames) {
    const statuspageComponentCodes = CARRIER_CODE_MAP[componentName]

    if (statuspageComponentCodes === null) {
      continue
    }

    if (
      Array.isArray(statuspageComponentCodes) &&
      statuspageComponentCodes.some(code => enabledCarrierCodes.value.includes(code))
    ) {
      return true
    }

    // This is a mechanism for allowing incidents to be shown for carriers that
    // haven’t yet been added to the `CARRIER_CODE_MAP`.
    if (statuspageComponentCodes === undefined) {
      return true
    }
  }

  return false
}

async function pollStatuspageIncidents(): Promise<void> {
  poll(updateIncidents, POLL_DELAY_IN_MINUTES * 60 * 1000).catch(() => {
    // Explicitly not handling errors throw by the poll function.
    // We use this behavior as a mechanism of stopping the polling process
    // in the case the underlying Statuspage.io endpoint can’t be reached (for example).
  })
}

/**
 * Preprocesses the incidents data.
 *
 * - Filters out all incidents that were previously dismissed.
 * - Limits the amount of incidents to be shown at any given time.
 * - Adds a `relativeTime` property to display time as “x days ago”.
 */
function preProcessIncidents(incidents: StatuspageIncident[]): Incident[] {
  return (
    incidents
      .filter(shouldShowIncident)
    // Limit the amount of incidents that can be shown at any given time.
      .slice(0, MAX_NUMBER_OF_INCIDENTS_TO_SHOW)
      .map((incident) => {
        const serializedIncident = serializeIncident(incident)

        serializedIncident.relativeTime = getTimeFromNow(incident.updated_at)

        return serializedIncident
      })
  )
}

/**
 * Serializes a Statuspage incident object
 * so that it only contains properties required to render it.
 */
function serializeIncident({ id, name, status, impact, shortlink }: StatuspageIncident): Incident {
  return { id, name, status, impact, shortlink, relativeTime: null }
}

/**
 * Filters incidents that should be shown to the user.
 *
 * The filter criteria are:
 *
 * 1. Incident was created after the user joined the platform.
 * 2. Incident is not too old.
 * 3. Incident was resolved within the last three hours.
 * 4. Incident with the same or older status was not dismissed before.
 * 5. Incident affects the user.
 */
function shouldShowIncident(incident: StatuspageIncident): boolean {
  // 1. Incident was created before the user joined the platform.
  const incidentCreatedDate = new Date(incident.created_at)
  if (incidentCreatedDate < userJoinedDate.value) {
    return false
  }

  // 2. Incident is too old.
  const incidentUpdatedDate = new Date(incident.updated_at)
  if (incidentUpdatedDate < incidentIsOldDate) {
    return false
  }

  // 3. Incident is resolved and was resolved more than three hours ago.
  if (incident.status === 'resolved' || incident.status === 'postmortem') {
    // We are sure resolved_at will be present in the API response here:
    const resolvedDate = new Date(incident.resolved_at!)
    const offsetHours = HOURS_TO_SHOW_RESOLVED_STATUS_FOR
    const resolvedIncidentTooOldDate = new Date(resolvedDate.setHours(resolvedDate.getHours() + offsetHours))

    if (currentDate < resolvedIncidentTooOldDate) {
      return false
    }
  }

  const dismissedStatus = dismissedIncidentStatuses.value?.get(incident.id)

  // 4. Incident was dismissed before, and its current status is not newer.
  if (dismissedStatus !== undefined && isStatusOlderOrEqual(incident.status, dismissedStatus)) {
    return false
  }

  // 5. Incident doesn’t affect the user.
  // For example, a carrier component is affected that the user doesn’t have enabled.
  if (!isUserAffectedByIncident(incident)) {
    return false
  }

  return true
}

/**
 * Stores a list of dismissed incident IDs along with the status of the respective incident.
 */
function storeDismissedIncidents(item: [string, StatuspageStatus][]): void {
  const storagePayload = JSON.stringify(item)
  try {
    window.localStorage.setItem(storageKey.value, storagePayload)
  } catch (error) {}
}

/**
 * Updates the incidents data after querying the Statuspage API.
 */
async function updateIncidents(): Promise<void> {
  const { data } = await getIncidents()

  if (Array.isArray(data.incidents) && data.incidents.length > 0) {
    incidents.value = preProcessIncidents(data.incidents)
  }
}
</script>

<style lang="scss" scoped>
.statuspage-widget {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: 200;

  @include breakpoint-min-width(600px) {
    margin-bottom: 20px;

    // The div is related to Vue’s list transition
    > div > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  @include breakpoint-min-width(large) {
    left: 240px;

    > div {
      display: flex;
      justify-content: start;
    }
  }
}

// Vue transitions
.sc-fade-enter-active,
.sc-fade-leave-active {
  position: absolute;

  --fade-transition-timing: ease-out;
}

.sc-fade-move {
  transition: opacity 0.2s ease-out, transform 0.5s;
}
</style>
